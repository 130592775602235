import {Box, BoxProps} from '@mui/material'
import React from 'react'

import {mergeSx} from '../../../../utils'

type DropdownNotificationProps = Pick<BoxProps, 'sx'>

/**
 * should be used inside parent with position relative
 */
export const DropdownNotification: React.FC<DropdownNotificationProps> = ({sx}) => {
  return (
    <Box
      sx={mergeSx(
        {
          position: 'absolute',
          right: 0,
          top: 0,
          width: ({spacing}) => spacing(2),
          height: ({spacing}) => spacing(2),
          backgroundColor: 'primary.light',
          zIndex: 1,
          borderRadius: ({spacing}) => spacing(1)
        },
        sx
      )}
    />
  )
}

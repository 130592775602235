import {CheckCircle, RadioButtonUnchecked, ArrowForward} from '@mui/icons-material'
import {Stack, SvgIcon, Typography, Button} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useGuidedTours} from '../../../components/guidedTour'
import {GuidedTour} from '../../../components/guidedTour/types'
import {GuidedTourStatus} from '../../../components/guidedTour/types/GuidedTour.enums'

interface GuidedTourListItemProps {
  guidedTour: GuidedTour
  tourKey: string
  onDropdownClose: () => void
}

export const GuidedTourListItem: React.FC<GuidedTourListItemProps> = ({
  tourKey,
  guidedTour,
  onDropdownClose
}) => {
  const {t} = useTranslation()
  const {tourStatuses, setActiveTour} = useGuidedTours()
  return (
    <Stack spacing={1}>
      <Stack direction="row" spacing={1} alignItems="center">
        {tourStatuses[tourKey] === GuidedTourStatus.COMPLETE ? (
          <SvgIcon sx={{color: ({palette}) => palette.success.main}} component={CheckCircle} />
        ) : (
          <SvgIcon component={RadioButtonUnchecked} />
        )}
        <Typography variant="button">{guidedTour.title}</Typography>
      </Stack>
      <Typography variant="body1">{guidedTour.description}</Typography>
      <Button
        variant="outlined"
        color="secondary"
        startIcon={<ArrowForward />}
        onClick={() => {
          onDropdownClose()
          setActiveTour(tourKey)
        }}
      >
        {t(
          `guidedTours.${
            tourStatuses[tourKey] === GuidedTourStatus.COMPLETE ? 'retakeTour' : 'takeTour'
          }`
        )}
      </Button>
    </Stack>
  )
}

import React, {createContext, useState, useMemo, useCallback} from 'react'

import {TourSteps} from './components/TourSteps'
import {getStatusesOfTours, setTourStatus} from './helpers/tourstatushelpers'
import type {GuidedTour} from './types'
import {GuidedTourStatus} from './types/GuidedTour.enums'

interface GuidedTourContextState {
  tours: Record<string, GuidedTour>
  activeTour: {key: string; tour: GuidedTour} | undefined
  tourStatuses: Record<string, GuidedTourStatus>
  setActiveTour: (tourKey: string | undefined) => void
  markTourAsCompleted: (tourKey: string) => void
}

export const GuidedToursContext = createContext<GuidedTourContextState | undefined>(undefined)

interface GuidedToursProviderProps {
  tours: Record<string, GuidedTour>
  enabledTours: Record<string, boolean>
  children: React.ReactNode
}

export const GuidedToursProvider = ({tours, enabledTours, children}: GuidedToursProviderProps) => {
  const [activeTourKey, setActiveTourTemp] = useState<string>()
  const setActiveTour = useCallback(
    (tour?: string) => {
      setActiveTourTemp(tour)
    },
    [setActiveTourTemp]
  )

  const activeTour = useMemo(
    () => (activeTourKey ? tours[activeTourKey] : undefined),
    [activeTourKey, tours]
  )

  const filteredTours: Record<string, GuidedTour> = Object.entries(tours).reduce(
    (accEnabledTours, [key, tour]) => ({
      ...accEnabledTours,
      ...(enabledTours[key] ? {[key]: tour} : {})
    }),
    {}
  )

  const markTourAsCompleted = useCallback(
    (key: string) => setTourStatus(key, GuidedTourStatus.COMPLETE),
    []
  )

  const tourStatuses = {
    ...getStatusesOfTours(Object.keys(filteredTours)),
    // we do keep active tour in local state, but to simplify logic below in component tree we do translate it as IN_PROGRESS status
    ...(activeTourKey ? {[activeTourKey]: GuidedTourStatus.IN_PROGRESS} : {})
  }

  const contextValue: GuidedTourContextState = {
    tours: filteredTours,
    tourStatuses,
    activeTour: activeTour && activeTourKey ? {key: activeTourKey, tour: activeTour} : undefined,
    setActiveTour,
    markTourAsCompleted
  }

  return (
    <GuidedToursContext.Provider value={contextValue}>
      <TourSteps
        activeTourKey={activeTourKey}
        activeTour={activeTour}
        markTourAsCompleted={markTourAsCompleted}
        setActiveTour={setActiveTour}
      />
      {children}
    </GuidedToursContext.Provider>
  )
}

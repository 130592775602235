import {ArrowDropDown, Error, Visibility, VisibilityOff} from '@mui/icons-material'
import {Box, IconButton, InputAdornment, MenuItem, TextField, TextFieldProps} from '@mui/material'
import classNames from 'classnames'
import queryString from 'query-string'
import React, {useState} from 'react'

import {InputMessage} from '../InputMessage/InputMessage'

import {useStyles} from './InputTextField.styles'

export type InputTextFieldProps = {
  endAdornment?: JSX.Element | null
  select?: boolean
  selectValues?: {value: string; label: string}[]
  'data-test-id'?: string
  error?: boolean
  menuItemsStyle?: string
  isPassword?: boolean
  passwordOptions?: {
    showPassword: boolean
    setShowPassword: (show: boolean) => void
  }
  helperText?: string | React.ReactNode
} & TextFieldProps

// eslint-disable-next-line complexity
export const InputTextField = ({
  endAdornment,
  select,
  selectValues,
  'data-test-id': dataTestId,
  error,
  menuItemsStyle,
  isPassword,
  passwordOptions,
  helperText,
  ...otherProps
}: InputTextFieldProps) => {
  const {classes} = useStyles()
  const [showPassword, setShowPassword] = useState(false)
  const [opened, setOpened] = useState(false)
  const [isAutoFill, setIsAutoFill] = useState(false)
  const isCarbonBank = queryString
    .parse(window.location.search)
    .ids_return_url?.includes('CarbonBank')

  const handleOnClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!otherProps.disabled) {
      otherProps?.onClick && otherProps?.onClick(event)
      selectValues && setOpened(!opened)
    }
  }

  const adornment = (
    <Box style={{display: 'flex'}}>
      {error && (
        <InputAdornment position="end" disablePointerEvents={true}>
          <Error style={{color: 'rgba(218, 9, 1, 1)'}} />
        </InputAdornment>
      )}
      {isPassword && (
        <InputAdornment
          position="end"
          onClick={() =>
            passwordOptions?.setShowPassword(!passwordOptions?.showPassword) ||
            setShowPassword(!showPassword)
          }
        >
          <IconButton tabIndex={-1}>
            {passwordOptions?.showPassword || showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>
      )}
      {selectValues && (
        <InputAdornment position="end">
          <IconButton onClick={() => setOpened(!opened)} tabIndex={-1}>
            <ArrowDropDown />
          </IconButton>
        </InputAdornment>
      )}
    </Box>
  )

  return (
    <Box>
      <TextField
        error={error}
        type={
          otherProps.type ||
          (isPassword
            ? passwordOptions?.showPassword || showPassword
              ? 'text'
              : 'password'
            : undefined)
        }
        select={select ? select : undefined}
        variant="filled"
        className={classNames(
          isCarbonBank ? classes.cbTextInput : classes.textInput,
          classes.disableAutofill,
          {
            [classes.textInputError]: error
          }
        )}
        onClick={handleOnClick}
        InputLabelProps={{shrink: isAutoFill || otherProps.InputLabelProps?.shrink}}
        InputProps={{
          endAdornment: endAdornment || adornment,
          className: classes.textInput,
          inputProps: {
            'data-test-id': dataTestId,
            autoCapitalize: 'off',
            spellCheck: 'false',
            autoCorrect: 'off'
          },
          // Hack to prevent label overlap when using chrome autofill https://github.com/mui/material-ui/issues/14427
          onAnimationStart: (e: React.AnimationEvent<HTMLDivElement>) => {
            e.animationName === 'mui-auto-fill' && setIsAutoFill(true)
          },
          onAnimationEnd: (e: React.AnimationEvent<HTMLDivElement>) =>
            e.animationName === 'mui-auto-fill-cancel' && setIsAutoFill(false),
          onFocus: () => setIsAutoFill(false)
        }}
        SelectProps={{
          IconComponent: () => null,
          open: opened
        }}
        fullWidth
        {...otherProps}
      >
        {selectValues &&
          selectValues.map(({value, label}) => (
            <MenuItem key={value} value={value} className={menuItemsStyle}>
              {label}
            </MenuItem>
          ))}
      </TextField>
      {helperText ? (
        typeof helperText === 'string' ? (
          <InputMessage message={helperText.toString()} />
        ) : (
          helperText
        )
      ) : null}
    </Box>
  )
}

import {DoneAll, Info} from '@mui/icons-material'
import {Box, Button, IconButton} from '@mui/material'
import classNames from 'classnames'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useStyles} from './ValidatableInput.styles'

type ValidatableInputProps = {
  input: React.ReactNode
  onValidate?: () => void
  onHint: () => void
}
export const ValidatableInput = ({input, onHint, onValidate}: ValidatableInputProps) => {
  const {classes} = useStyles()
  const {t} = useTranslation()

  return (
    <Box style={{display: 'flex', gap: '12px'}}>
      <Box style={{flex: 1}}>{input}</Box>
      {onValidate && (
        <Button
          variant="outlined"
          classes={{root: classes.button, disabled: classes.validateButtonDisabled}}
          onClick={onValidate}
          startIcon={<DoneAll fontSize="small" />}
        >
          {t('inputs.validate')}
        </Button>
      )}
      <IconButton onClick={onHint} className={classNames(classes.button, classes.hintButton)}>
        <Info style={{color: '#016AD4'}} />
      </IconButton>
    </Box>
  )
}

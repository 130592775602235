import {Link, Typography} from '@mui/material'
import React from 'react'

export const getOssAttributionLink = () => {
  if (process.env.REACT_APP_STAGE === 'prod') {
    return 'https://legal.hconnect.digital/ossattribution'
  } else if (location.hostname === 'localhost') {
    return 'http://localhost:3010/ossattribution'
  } else {
    return `https://legal-${process.env.REACT_APP_STAGE}.hconnect.digital/ossattribution`
  }
}

export const OssAttributionLinkDark = () => (
  <Link
    underline="none"
    href={getOssAttributionLink()}
    rel="noopener noreferrer"
    data-test-id={'oss-attribution'}
    target="_blank"
  >
    <Typography
      variant="body2"
      color="text.secondary"
      data-test-id="footer-copyright-note"
      fontSize={12}
      lineHeight={'16px'}
      sx={{overflowWrap: 'break-word', textDecoration: 'underline'}}
    >
      Open Source Software Attribution
    </Typography>
  </Link>
)

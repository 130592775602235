import {NavigateBefore} from '@mui/icons-material'
import {Divider, Stack, Typography, Box} from '@mui/material'
import React from 'react'

import {DropdownMenuButtonLink} from '../../../DropdownMenuButtonLink'

interface DropdownDetailsProps {
  header: {title: string; icon: React.ReactNode; onHeaderClick: () => void}
  children?: React.ReactNode
}

export const DropdownDetails: React.FC<DropdownDetailsProps> = ({children, header}) => {
  const {title, icon, onHeaderClick} = header
  return (
    <Stack spacing={2} sx={{bgcolor: 'common.white'}}>
      <Box>
        <DropdownMenuButtonLink
          startIcon={<NavigateBefore color="secondary" />}
          content={
            <Stack direction="row" spacing={1} sx={{width: 1}}>
              <Box sx={{width: ({spacing}) => spacing(2), height: ({spacing}) => spacing(2)}}>
                {icon}
              </Box>
              <Typography variant="body1" sx={{textAlign: 'center'}}>
                {title}
              </Typography>
            </Stack>
          }
          contentPosition="center"
          onClick={onHeaderClick}
        />
        <Divider sx={{mx: 2}} />
      </Box>
      {children}
    </Stack>
  )
}

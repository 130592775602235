import {Divider, Stack} from '@mui/material'
import React from 'react'

import {GuidedTour} from '../../../components/guidedTour/types'

import {GuidedTourListItem} from './GuidedTourListItem'

interface LearnMoreSectionProps {
  guidedTours: Record<string, GuidedTour>
  onClose: () => void
}

export const LearnMoreSection: React.FC<LearnMoreSectionProps> = ({guidedTours, onClose}) => {
  return (
    <Stack spacing={2} sx={{px: 3}}>
      {Object.entries(guidedTours).map(([tourKey, tour], index) => (
        <React.Fragment key={index}>
          <GuidedTourListItem tourKey={tourKey} guidedTour={tour} onDropdownClose={onClose} />
          <Divider />
        </React.Fragment>
      ))}
    </Stack>
  )
}

import {Divider, Typography, Box} from '@mui/material'
import React from 'react'

interface DropdownMenuButtonLinkProps {
  onClick: () => void
  content: string | React.ReactElement
  dataTestId?: string
  contentPosition?: 'center' | 'left' | 'right'
  startIcon: React.ReactNode
  endIcon?: React.ReactNode
}

export const DropdownMenuButtonLink: React.FC<DropdownMenuButtonLinkProps> = ({
  content,
  contentPosition = 'left',
  dataTestId = 'account-menu-button',
  onClick,
  startIcon,
  endIcon
}) => {
  return (
    <>
      <Divider sx={{mx: 2}} />
      <Box
        sx={({palette}) => ({
          p: 2,
          background: palette.common.white,
          '&:hover': {
            backgroundColor: palette.grey[100],
            cursor: 'pointer'
          }
        })}
        onClick={onClick}
        data-test-id={dataTestId}
      >
        <Box
          sx={{
            width: 1,
            display: 'grid',
            gridTemplateColumns: ({spacing}) => `${spacing(2)} 1fr ${spacing(2)}`,
            columnGap: 1,
            alignItems: 'center'
          }}
        >
          <Box sx={{display: 'flex', alignItems: 'center', position: 'relative'}}>{startIcon}</Box>
          <Box sx={{display: 'flex', alignItems: 'center', justifySelf: contentPosition}}>
            {typeof content === 'string' ? (
              <Typography variant="body1" color="text.secondary">
                {content}
              </Typography>
            ) : (
              content
            )}
          </Box>
          <Box sx={{display: 'flex', alignItems: 'center', position: 'relative'}}>{endIcon}</Box>
        </Box>
      </Box>
    </>
  )
}

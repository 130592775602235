import {SxProps, Theme} from '@mui/material'
import {SystemStyleObject} from '@mui/system/styleFunctionSx/styleFunctionSx'

export const mergeSxs = (...sxArray: (SxProps<Theme> | undefined)[]): SxProps<Theme> =>
  sxArray.filter(Boolean).flatMap((sx) => (Array.isArray(sx) ? sx : [sx]))

export const mergeSx = (
  sxA: SystemStyleObject<Theme> | ((theme: Theme) => SystemStyleObject<Theme>),
  sxB?: SxProps<Theme>
): SxProps<Theme> => mergeSxs(sxA, sxB)

import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()(() => ({
  button: {
    textTransform: 'none',
    whiteSpace: 'nowrap',
    padding: '13px 16px !important',
    color: '#00274D',
    fontWeight: 500,
    border: '1px solid rgba(0, 39, 77, 0.1)',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.08)',
    '&:hover': {
      border: '1px solid rgba(0, 39, 77, 0.1) !important'
    }
  },
  validateButtonDisabled: {
    opacity: 0.3,
    color: '#00274D !important'
  },
  hintButton: {
    borderRadius: '6px'
  }
}))

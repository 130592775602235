import {appUrl, hproduce, MY_TASKS} from '@hconnect/common/hproduce/config'
import {getLanguageTitle} from '@hconnect/common/utils/language.util'
import {
  ContentPaste,
  ExitToApp,
  HelpOutline,
  NavigateNext,
  Translate,
  Assignment,
  Login,
  Code,
  PrivacyTip,
  Business,
  Gavel
} from '@mui/icons-material'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import type {GuidedTour} from '../../../../components/guidedTour/types'
import {GuidedTourStatus} from '../../../../components/guidedTour/types/GuidedTour.enums'
import {LanguageSwitcher, Locale, Locales} from '../../../LanguageSwitcher'
import {LearnMoreSection} from '../../learnMoreSection/LearnMoreSection'
import {DropdownLink} from '../components/DropdownLinks'
import {DropdownNotification} from '../components/DropDownNotification'

const decodeLocale = (locale: string) => {
  return locale.split('-') || []
}

const getLocaleCode = (locales: Locale[], localeCode: string) => {
  return locales?.find((locale) => locale.code === localeCode)?.code
}

export interface LinkDetail {
  title: string
  icon: React.ReactNode
  onGoBack: () => void
  content: React.ReactNode
}

interface UseDropdownLinksParams {
  shouldEnableMyTasks: boolean
  closeDropdownOnLanguageSwitch?: boolean
  guidedTours?: {tours: Record<string, GuidedTour>; statuses: Record<string, GuidedTourStatus>}
  onCloseDropdown: () => void
  setLinkDetailsSection: React.Dispatch<React.SetStateAction<LinkDetail | undefined>>
  logoutButtonText?: string
  imprintText?: string
  osSoftwareAttributionText?: string
  signInText?: string
  tcText?: string
  dppText?: string
  acText?: string
  logout?: () => void
  locales?: Locales
  selectLanguage?: (lang: string) => void
  imprint?: () => void
  osSoftwareAttribution?: () => void
  signIn?: () => void
  tc?: () => void
  dpp?: () => void
  ac?: () => void
}

export const useDropdownLinks = ({
  guidedTours,
  shouldEnableMyTasks,
  closeDropdownOnLanguageSwitch,
  onCloseDropdown,
  setLinkDetailsSection,
  logoutButtonText,
  logout,
  locales,
  selectLanguage,
  imprint,
  osSoftwareAttribution,
  signIn,
  signInText,
  imprintText,
  tcText,
  dppText,
  acText,
  osSoftwareAttributionText,
  tc,
  dpp,
  ac
}: UseDropdownLinksParams) => {
  const {t} = useTranslation()

  const newGuidedTours =
    guidedTours &&
    Object.entries(guidedTours.tours).filter(
      ([tourKey]) => guidedTours.statuses[tourKey] === GuidedTourStatus.NEW
    )
  const isNewGuidedToursAvailable = newGuidedTours ? newGuidedTours.length > 0 : false

  const currentLocale =
    (getLocaleCode(locales?.locales ?? [], locales?.defaultLocale ?? '') ||
      locales?.defaultLocale) ??
    ''

  const [currentLanguage, currentCountry] = decodeLocale(currentLocale)

  const languageTitle =
    getLanguageTitle(currentLanguage, currentCountry) || t(`locales.${currentLocale}`)

  return useMemo(
    // eslint-disable-next-line complexity
    (): DropdownLink[] => [
      ...(shouldEnableMyTasks
        ? [
            {
              title: t('myTaskPage', 'My Tasks'),
              onClick: () => {
                window.location.replace(`${appUrl(hproduce.url)}${MY_TASKS}`)
              },
              startIcon: <ContentPaste color="secondary" />,
              dataTestId: 'account-menu-my-tasks-button'
            }
          ]
        : []),
      ...(guidedTours
        ? [
            {
              title: t('guidedTours.learnMore'),
              onClick: () =>
                setLinkDetailsSection({
                  title: t('guidedTours.learnMore'),
                  icon: <HelpOutline color="secondary" />,
                  onGoBack: () => setLinkDetailsSection(undefined),
                  content: (
                    <LearnMoreSection guidedTours={guidedTours.tours} onClose={onCloseDropdown} />
                  )
                }),
              startIcon: <HelpOutline color="secondary" />,
              endIcon: isNewGuidedToursAvailable ? (
                <DropdownNotification sx={{position: 'relative'}} />
              ) : (
                <NavigateNext color="secondary" />
              ),
              dataTestId: 'account-menu-guided-tours-button'
            }
          ]
        : []),
      ...(locales && languageTitle
        ? [
            {
              title: languageTitle,
              startIcon: <Translate color="secondary" />,
              endIcon: (
                <NavigateNext color="secondary" data-test-id={'language-switcher-expand-btn'} />
              ),
              onClick: () =>
                setLinkDetailsSection({
                  title: languageTitle,
                  icon: <Translate color="secondary" />,
                  onGoBack: () => setLinkDetailsSection(undefined),
                  content: (
                    <LanguageSwitcher
                      locales={locales}
                      onSelectLanguage={(lang) => {
                        selectLanguage?.(lang)
                        closeDropdownOnLanguageSwitch && onCloseDropdown()
                      }}
                    />
                  )
                }),
              dataTestId: 'account-menu-language-switcher-button'
            }
          ]
        : []),
      ...(logoutButtonText && logout
        ? [
            {
              title: logoutButtonText,
              onClick: logout,
              startIcon: <ExitToApp color="error" />,
              dataTestId: 'account-menu-logout-button'
            }
          ]
        : []),
      ...(imprint && imprintText
        ? [
            {
              title: imprintText,
              onClick: imprint,
              startIcon: <Assignment style={{color: '#54708C'}} />,
              dataTestId: 'account-menu-imprint-button'
            }
          ]
        : []),
      ...(osSoftwareAttribution && osSoftwareAttributionText
        ? [
            {
              title: osSoftwareAttributionText,
              onClick: osSoftwareAttribution,
              startIcon: <Code style={{color: '#54708C'}} />,
              dataTestId: 'account-menu-os-software-attribution-button'
            }
          ]
        : []),
      ...(signIn && signInText
        ? [
            {
              title: signInText,
              onClick: signIn,
              startIcon: <Login style={{color: '#54708C'}} />,
              dataTestId: 'account-menu-sign-in-button'
            }
          ]
        : []),
      ...(tc && tcText
        ? [
            {
              title: tcText,
              onClick: tc,
              startIcon: <Gavel style={{color: '#54708C'}} />,
              dataTestId: 'account-menu-tc-button'
            }
          ]
        : []),
      ...(dpp && dppText
        ? [
            {
              title: dppText,
              onClick: dpp,
              startIcon: <PrivacyTip style={{color: '#54708C'}} />,
              dataTestId: 'account-menu-dpp-button'
            }
          ]
        : []),
      ...(ac && acText
        ? [
            {
              title: acText,
              onClick: ac,
              startIcon: <Business style={{color: '#54708C'}} />,
              dataTestId: 'account-menu-ac-button'
            }
          ]
        : [])
    ],
    [
      acText,
      dppText,
      imprintText,
      osSoftwareAttributionText,
      closeDropdownOnLanguageSwitch,
      signInText,
      tcText,
      guidedTours,
      logout,
      logoutButtonText,
      isNewGuidedToursAvailable,
      onCloseDropdown,
      setLinkDetailsSection,
      locales,
      languageTitle,
      selectLanguage,
      shouldEnableMyTasks,
      imprint,
      osSoftwareAttribution,
      signIn,
      tc,
      dpp,
      ac,
      t
    ]
  )
}

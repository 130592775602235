import {List} from '@mui/material'
import React from 'react'

import {DropdownMenuButtonLink} from '../../../DropdownMenuButtonLink'

export interface DropdownLink {
  title: string
  onClick: () => void
  startIcon: React.ReactNode
  endIcon?: React.ReactNode
  dataTestId?: string
}

interface DropdownLinksProps {
  header?: React.ReactNode
  links: DropdownLink[]
  footer?: React.ReactNode
}

export const DropdownLinks: React.FC<DropdownLinksProps> = ({header, footer, links}) => {
  return (
    <List disablePadding subheader={header} sx={{backgroundColor: 'common.white'}}>
      {links.map(({startIcon, endIcon, title, onClick, dataTestId}, index) => (
        <DropdownMenuButtonLink
          key={index}
          startIcon={startIcon}
          endIcon={endIcon}
          content={title}
          onClick={onClick}
          dataTestId={dataTestId}
        />
      ))}
      {footer}
    </List>
  )
}

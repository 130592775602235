import {Box} from '@mui/material'
import React from 'react'

import {useStyles} from './InputMessage.styles'

import {Typography} from '@hconnect/uikit'

type InputMessageProps = {
  message: string
  variant?: 'error' | 'info'
}
export const InputMessage = ({message, variant = 'error'}: InputMessageProps) => {
  const {classes} = useStyles()
  return (
    <Box
      className={
        variant === 'error' ? classes.inputErrorMessageContainer : classes.inputInfoMessageContainer
      }
      data-test-id={`input-${variant}-message`}
    >
      <Typography style={{color: '#00274D', fontSize: '16px'}}>{message}</Typography>
    </Box>
  )
}

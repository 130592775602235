import {getLanguageTitle} from '@hconnect/common/utils/language.util'
import {List, ListItem, Theme, Typography, useTheme} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {flags} from '../../common/flags'

import {DropdownMenuButtonLink} from './DropdownMenuButtonLink'

export interface Locale {
  code: string
  name: string
}

export interface Locales {
  defaultLocale: string
  locales: Locale[] | null
}

interface LanguageSwitcherProps {
  locales: Locales
  onSelectLanguage: (language: string) => void
}

const flagStyle = ({spacing}: Theme): React.CSSProperties => ({
  width: spacing(2),
  height: spacing(2)
})

export const LanguageSwitcher: React.FC<LanguageSwitcherProps> = ({locales, onSelectLanguage}) => {
  const theme = useTheme()
  const handleLanguageChange = (code: string) => {
    onSelectLanguage(code)
  }

  const {t, i18n} = useTranslation()
  const language = i18n.language
  const localeListLabel = t(`locales.${language}`)

  const selectableLocales = () => {
    if (!locales?.locales) {
      return []
    }
    return locales.locales.filter((locale) => locale.code !== locales.defaultLocale)
  }

  if (selectableLocales().length === 0) {
    return null
  }

  const decodeLocale = (locale: string) => {
    return locale.split('-') || []
  }

  return (
    <List component="div" disablePadding>
      <ListItem>
        <Typography component="span" color="text.secondary" variant="body2">
          {localeListLabel}
        </Typography>
      </ListItem>
      {selectableLocales()?.map((locale: Locale) => {
        const [language, country] = decodeLocale(locale.code)
        return (
          <DropdownMenuButtonLink
            key={locale.code}
            startIcon={
              <img style={flagStyle(theme)} src={flags[locale.code] as string} alt={locale.name} />
            }
            content={getLanguageTitle(language, country) || t(`locales.${locale.code}`)}
            onClick={() => handleLanguageChange(locale.code)}
            dataTestId={`language-switcher-${locale.code}`}
          />
        )
      })}
    </List>
  )
}

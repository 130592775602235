import {useContext} from 'react'

import {GuidedToursContext} from '../GuidedTourProvider'

export const useGuidedTours = () => {
  const context = useContext(GuidedToursContext)
  if (!context) {
    throw new Error('useTour hook must be used within a TourProvider')
  }
  return context
}

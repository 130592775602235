import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()(() => ({
  textInput: {
    outline: 'none',
    border: 'none',
    '& .MuiInputBase-root': {
      borderRadius: '4px',
      height: '48px',
      border: '1.5px solid rgba(0, 39, 77, 0.15)',
      background:
        'linear-gradient(0deg, #FBFBFC, #FBFBFC),linear-gradient(0deg, rgba(0, 39, 77, 0.15), rgba(0, 39, 77, 0.15))',
      '&.Mui-focused': {
        border: '1.5px solid #016AD4'
      },
      '&.Mui-disabled': {
        opacity: 0.5
      }
    },
    '& .MuiInputBase-input': {
      outline: 'none',
      border: 'none',
      paddingLeft: '16px',
      paddingTop: '16px',
      paddingBottom: 0
    },
    '& .MuiFormLabel-root': {
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '22px',
      paddingLeft: '8px',
      marginTop: '-4px',
      '&.Mui-focused': {
        fontWeight: 400
      },
      '&.Mui-disabled': {
        opacity: 0.5
      }
    }
  },
  cbTextInput: {
    outline: 'none',
    border: 'none',
    '& .MuiInputBase-root': {
      borderRadius: '4px',
      height: '48px',
      border: '1.5px solid rgba(0, 39, 77, 0.15)',
      background:
        'linear-gradient(0deg, #FBFBFC, #FBFBFC),linear-gradient(0deg, rgba(0, 39, 77, 0.15), rgba(0, 39, 77, 0.15))',
      '&.Mui-focused': {
        border: '1.5px solid rgba(0, 120, 55, 1)'
      },
      '&.Mui-disabled': {
        opacity: 0.5
      }
    },
    '& .MuiInputBase-input': {
      outline: 'none',
      border: 'none',
      paddingLeft: '16px',
      paddingTop: '16px',
      paddingBottom: 0
    },
    '& .MuiFormLabel-root': {
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '22px',
      paddingLeft: '8px',
      marginTop: '-4px',
      '&.Mui-focused': {
        fontWeight: 400
      },
      '&.Mui-disabled': {
        opacity: 0.5
      }
    }
  },
  textInputError: {
    '& .MuiFilledInput-root': {
      border: '1.5px solid rgba(218, 9, 1, 1)'
    },
    '& .MuiFormLabel-root': {
      color: 'rgba(84, 112, 140, 1)'
    }
  },
  disableAutofill: {
    '& input:-internal-autofill-selected': {
      '-webkit-box-shadow': '0 0 0 1000px #FBFBFC inset !important'
    }
  }
}))

import {Link as MaterialUiLink, LinkProps} from '@material-ui/core'
import React from 'react'

interface Props extends LinkProps {
  children: React.ReactNode
}

const Link = ({children, ...props}: LinkProps) => (
  <MaterialUiLink {...props}>{children}</MaterialUiLink>
)

const ExternalLink = ({children, ...props}: Props) => (
  <MaterialUiLink {...props} target="_blank" rel="noopener noreferrer">
    {children}
  </MaterialUiLink>
)

export {Link, ExternalLink}

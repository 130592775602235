import {Divider, Typography, Box, Link} from '@mui/material'
import React from 'react'

import {OssAttributionLinkDark} from '../../../footer'
import {FooterLink} from '../../../footer/ShellFooter'

export type DropdownFooterProps = {
  copyrightNote?: string
} & (
  | {
      footerLinks: FooterLink[]
      // function from react-router-dom v5 or v6 push or navigate
      navigate: (route: string) => void
    }
  | {
      footerLinks?: never
      navigate?: never
    }
)

export const DropdownFooter = ({footer}: {footer: DropdownFooterProps}) => {
  const {
    copyrightNote = '\u00a9 2022 HeidelbergMaterials. All rights reserved.',
    footerLinks = [],
    navigate
  } = footer
  return (
    <>
      <Divider sx={{mx: 2}} />
      <Box
        sx={{
          p: 2,
          backgroundColor: 'common.white',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column'
        }}
      >
        <Typography
          variant="body2"
          color="text.secondary"
          data-test-id="footer-copyright-note"
          fontSize={12}
          lineHeight={'16px'}
          sx={{overflowWrap: 'break-word'}}
        >
          {copyrightNote}
        </Typography>
        <OssAttributionLinkDark />
        {footerLinks && (
          <Box data-test-id="footer-links" display={'flex'}>
            {footerLinks.map(({label, link}, index) => {
              return (
                <>
                  <Link
                    key={label}
                    onClick={() => navigate?.(link)}
                    underline={'always'}
                    color="text.secondary"
                    display={'inline-block'}
                  >
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      data-test-id="footer-copyright-note"
                      fontSize={12}
                      lineHeight={'16px'}
                      sx={{overflowWrap: 'break-word'}}
                    >
                      {label}
                    </Typography>
                  </Link>
                  {footerLinks.length - 1 !== index && (
                    <Typography
                      mr={1}
                      ml={1}
                      variant="body2"
                      color="text.secondary"
                      data-test-id="footer-copyright-note-space"
                      fontSize={12}
                      lineHeight={'16px'}
                    >
                      |
                    </Typography>
                  )}
                </>
              )
            })}
          </Box>
        )}
      </Box>
    </>
  )
}

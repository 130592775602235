import {IconButton, IconButtonProps, SxProps, Theme} from '@mui/material'
import {isFunction} from 'lodash'
import React, {Children, cloneElement, forwardRef, isValidElement, Attributes} from 'react'

import {useIsMobile} from '../hooks'

type TransparentButtonProps = Omit<IconButtonProps, 'sx'> & {
  sx?: Omit<SxProps<Theme>, 'color' | 'borderColor' | 'border' | 'p'>
}

export const ShellIconButton = forwardRef<HTMLElement, TransparentButtonProps>(
  // removing the unused ref causes a React warning (very weird)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({children, sx, ...props}, ref) => {
    const isMobile = useIsMobile()

    return (
      <IconButton
        sx={(theme) => ({
          border: 1,
          borderColor: theme.palette.grey[200],
          boxShadow: `0px 2px 2px 0px ${theme.palette.grey[200]}`,
          p: '11px',
          color: theme.palette.text.primary,
          '&:hover': isMobile
            ? {
                background: theme.palette.grey[200],
                boxShadow: 'none'
              }
            : {
                background: theme.palette.grey[100]
              },
          '&:active': {
            background: theme.palette.grey[200],
            boxShadow: 'none'
          },
          ...(isFunction(sx) ? sx(theme) : sx)
        })}
        {...props}
      >
        {Children.map(children, (child) => {
          return (
            isValidElement(child) &&
            cloneElement(child, {
              style: {fontSize: '16px'}
            } as Attributes)
          )
        })}
      </IconButton>
    )
  }
)

import {Link} from '@mui/material'
import React from 'react'

import {TextOnBackground} from '../../components'

export const getOssAttributionLink = () => {
  if (process.env.REACT_APP_STAGE === 'prod') {
    return 'https://legal.hconnect.digital/ossattribution'
  } else if (location.hostname === 'localhost') {
    return 'http://localhost:3010/ossattribution'
  } else {
    return `https://legal-${process.env.REACT_APP_STAGE}.hconnect.digital/ossattribution`
  }
}

export const OssAttributionLink = () => (
  <Link
    underline="none"
    href={getOssAttributionLink()}
    rel="noopener noreferrer"
    data-test-id={'oss-attribution'}
    target="_blank"
  >
    <TextOnBackground variant="caption" contrast="low" highlightOnHover>
      Open Source Software Attribution
    </TextOnBackground>
  </Link>
)
